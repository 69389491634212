@tailwind base;
@tailwind components;
@tailwind utilities;

.flatfile-close-button {
  display: none !important;
  visibility: hidden;
}

.custom-checkbox {
  accent-color: theme("colors.purple.700");
}

/* Or more specifically */
.custom-checkbox:checked {
  background-color: theme("colors.purple.700");
  border-color: theme("colors.purple.700");
}
